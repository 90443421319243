.backButton {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 1px 0 7px rgba(0,0,0,0.07);
  color: #975DA1;
  display: inline-block;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  padding: 0.25rem 0.5rem;
  text-decoration: none;
}

.backButton:hover {
  background-color: #975DA1;
  color: #fff;
  transition: background-color 0.25s, color 0.25s;
}

.createReviewForm {
  font-weight: 500;
  text-align: left;
  padding-bottom: 2rem;
}

.createReviewForm__block {
  margin-bottom: 2rem;
}

.createReviewForm__block label {
  display: block;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.createReviewForm input,
.createReviewForm textarea {
  background: #444;
  border: none;
  color: #eee;
  font-size: 1rem;
  outline: none;
  padding: 0.5rem;
  width: 100%;
}

.createReviewForm__block textarea {
  min-height: 10rem;
}

.createReviewForm h3 {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.createReviewForm h4 {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.scoreSection__category {
  border-bottom: 0.125rem solid #444;
  margin-bottom: 3rem;
  padding-bottom: 4rem;
}

.slug {
  font-size: 0.875rem;
}

.checkboxContainer .checkbox {
  margin-right: 0.5rem;
  width: initial;
}

.checkboxContainer {
  display: block;
  padding: 0.75rem 1rem;
  width: 25%;
}

.cta-wrapper {
  background: rgba(0,0,0,0.25);
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
}

.cta-container {
  display: flex;
  margin: 0 auto;
  max-width: 40rem;
}

.cta-btn {
  max-width: none;
  padding: 0;
  width: 75%;
}
