.appLogo {
  margin: 0 auto;
}

.adminWrapper {
  text-align: center;
}

.adminWrapper__heading {
  display: none;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.adminWrapper input {
  height: 3rem;
  width: 20rem;
}

.adminWrapper input,
.adminWrapper textarea {
  border-radius: 0.25rem;
  border: none;
  box-sizing: border-box;
  font-size: 1.5rem;
  outline: none;
  padding: 0.5rem;
}

.adminWrapper input::placeholder,
.adminWrapper textarea::placeholder {
  color: #ccc;
}

.admindWrapper button {
  background: none;
  border-radius: 0.25rem;
  border: 0.125rem solid #555;
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.125rem;
  margin: 0 auto;
  max-width: 20rem;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  width: 100%;
}
