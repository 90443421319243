.gameTileWrapper {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.gameTile__cover img {
  min-height: 20vh;
  height: auto;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 2/3;
}

.gameTile__meta {
  position: absolute;
  left: 0;
  top: 40%;
}

.gameTile__score,
.gameTile__gameTitle {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  float: left;
  padding: 0.5rem;
}

.gameTile__score {
  clear: both;
}

.gameTile__gameTitle {
  margin-bottom: 0.5rem;
}

@media (max-width: 374px) {
  .gameTile__score,
  .gameTile__gameTitle {
    font-size: 1.5rem;
  }
}
