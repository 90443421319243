.reviewWrapper {
  margin-bottom: 4rem;
}

.reviewWrapper__game {
  display: block;
  margin-bottom: 2rem;
  position: relative;
  text-decoration: none;
}

.gameMetaContainer {
  bottom: 2rem;
  margin-left: -1rem;
  position: absolute;
}

.gameMetaContainer--inner {
  background: rgba(0, 0, 0, 0.75);
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: table;
  padding: 0.5rem 1rem;
  width: auto;
}

.gameMetaContainer--inner:first-of-type {
  margin-bottom: 1rem;
}

.gameMetaContainer__platforms {
  color: #777;
  font-weight: 400;
}

.gameMetaContainer__name {
  font-size: 1.5rem;
}

.gameMetaContainer__name,
.score__rating,
.subCategoryContainer__categoryScore {
  color: #fff;
}

.gameMetaContainer__coverArt {
  border-radius: 0.5rem;
  height: auto;
  margin-bottom: 2rem;
  margin: 0 auto;
  max-height: 24.75rem;
  max-width: 20rem;
  overflow: hidden;
}

.gameMetaContainer__coverArt img {
  display: block;
  height: auto;
  max-width: 100%;
}

.score__rating {
  font-size: 2.5rem;
  font-weight: 700;
}

.reviewSeeAllCTA {
}

.reviewSeeAllCTA--hidden {
  display: none;
}

.reviewSummaryContainer,
.reviewSummaryContainer > p {
  margin-bottom: 3rem;
}

.summaryProsCons,
.summaryProsCons > span {
  display: block;
}

.summaryProsCons__rest {
}

.reviewSummaryContainer__blurb {
  color: #ddd;
  font-family: Merriweather, serif;
  font-size: 1.5rem;
  hyphens: auto;
}

.subCategoryContainer__scoreSummary {
  font-size: 1.125rem;
}

.gameMetaContainer__platforms,
.score__total,
.categoryScore__total {
  color: #9e9e9e;
}

.subCategoryList__heading,
.reviewSummaryContainer__heading,
.summaryProsCons__prefix {
  color: #777;
}

.subCategoryList__heading,
.reviewSummaryContainer__heading,
.gameMetaContainer__platforms,
.summaryProsCons__prefix {
  font-size: 1.125rem;
  font-weight: 700;
}

.subCategoryList__heading,
.reviewSummaryContainer__heading,
.summaryProsCons__prefix {
  margin-bottom: 0.5rem;
  letter-spacing: 0.125rem;
}

.subCategoryList {
  display: flex;
  overflow-y: scroll;
  padding: 0 1rem 1rem 1rem;
  width: 100vw;
  position: relative;
  left: -1rem;
  box-sizing: border-box;
  margin-top: 0.5rem;
}

.subCategoryContainer {
  text-align: center;
  padding: 0.5rem 1.5rem;
  background: #222;
  border-radius: 0.24rem;
  margin-right: 0.5rem;
}

.subCategoryContainer:last-child {
  margin-right: 0;
}

.subCategoryContainer__categoryScore,
.subCategoryContainer__categoryName {
  color: #ddd;
}

.subCategoryContainer__categoryScore {
  font-size: 1.5rem;
}

.categoryScore__total {
  font-size: 1rem;
}

.subCategoryContainer__categoryName {
  font-size: 1rem;
  color: #9e9e9e;
}

.subCategoryContainer__scoreSummary {
  font-size: 1rem;
  font-weight: 400;
}

@media (max-width: 320px) {
  .reviewSummaryContainer__title,
  .reviewSummaryContainer__blurb,
  .summaryProsCons,
  .subCategoryList__heading,
  .subCategoryContainer__categoryName {
    font-size: 1rem;
  }

  .subCategoryContainer__categoryScore {
    font-size: 2rem;
    margin-top: -0.25rem;
  }

  .subCategoryContainer__scoreSummary {
    font-size: 0.875rem;
  }
}

@media (min-width: 480px) {
  .gameMetaContainer--inner {
    border-radius: 0.25rem;
  }

  .gameMetaContainer {
    left: 50%;
    margin-left: 0;
    max-width: 80%;
    transform: translateX(-50%);
    width: 100%;
  }

  .gameMetaContainer > div,
  .reviewWrapper__game > div {
    margin: 0 auto;
  }
}

@media (min-width: 640px) {
  .gameMetaContainer__name {
    font-size: 2rem;
  }

  .gameMetaContainer {
  }

  .reviewSummaryContainer__blurb {
    font-size: 2rem;
    hyphens: none;
  }

  .summaryProsCons {
    font-size: 1.375rem;
  }

  .subCategoryContainer__scoreSummary {
    font-size: 1.125rem;
  }

  .subCategoryContainer__categoryScore {
    font-size: 2rem;
  }

  .scoreBreakdown {
    text-align: center;
  }

  .subCategoryList {
    overflow-y: initial;
    margin: 0.5rem auto 0;
    justify-content: center;
    width: auto;
  }
}
